import React from "react";
import { IonCol, IonRow, IonGrid } from "@ionic/react";
import DOMPurify from "dompurify";
import "./KindmapContentText.css";

interface KindmapContentTextProps {
    content: Parse.Object<{
        Text: string;
    }>;
}

const KindmapContentText: React.FC<KindmapContentTextProps> = ({ content }) => {
    // Sanitize the HTML content
    const rawText =
        content.attributes.Text ||
        "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
    const sanitizedContent = DOMPurify.sanitize(rawText);

    return (
        <IonGrid className="ion-padding padding-materials">
            <IonRow className="ion-padding">
                <IonCol>
                    <div
                        className="text-materials"
                        dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                    ></div>
                </IonCol>
            </IonRow>

            <div className="spacer-div-materials-bottom-exercise"></div>
        </IonGrid>
    );
};

export default KindmapContentText;