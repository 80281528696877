import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { initializeParse } from '@parse/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './style/Variables.css';

import PublicContainer from './pages/container/Public';
import PrivateContainer from './pages/container/Private';
import Login from './pages/container/Login';

const App: React.FunctionComponent = () => {

  /* Initialize Parse Server */
  initializeParse(
    'https://parseapi.back4app.com',
    '8TpZVdueqylnVa1hDcV6sv5VfP8gp5atQ0fIqVo7',
    'ch221JzylXklBBk4nB1QxdHTQV1o2jTaYmhQUvG3'
  );

  setupIonicReact({
    mode: 'md'
  });

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>

          <Route path="/public">
            <PublicContainer />
          </Route>

          <Route path="/app">
            <PrivateContainer />
          </Route>

          <Route exact path="/">
            <Redirect to="/public/home" />
          </Route>

          <Route path="/login">
            <Login />
          </Route>

        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
