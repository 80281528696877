import React from 'react';
import { IonCard, IonGrid } from '@ionic/react';
import KindmapAudioPlayer from './KindmapAudioPlayer';
import './KindmapContentAudio.css';

interface KindmapContentAudioProps {
    content: Parse.Object;
}

const KindmapContentAudio: React.FC<KindmapContentAudioProps> = ({ content }) => {
    const { Audio, Text } = content?.attributes || {};

    return (
        <IonGrid className="ion-padding content-audio-grid">
            {Audio?.url() && (
                <IonCard className="teste audio-card ion-padding">
                    <KindmapAudioPlayer url={Audio.url()} title={Text ?? 'Conteúdo em áudio'} />
                </IonCard>
            )}

            <div className="spacer-div-materials-bottom-exercise"></div>
        </IonGrid>
    );
};

export default KindmapContentAudio;