import React from 'react';
import { IonCol, IonRow, IonGrid, IonText } from '@ionic/react';
import KindmapVideoPlayer from './KindmapVideoPlayer';
import './KindmapContentVideo.css';

interface KindmapContentVideoProps {
    content: {
        attributes: {
            Text: string;
            Url: string;
        };
    };
}

const KindmapContentVideo: React.FC<KindmapContentVideoProps> = ({ content }) => {
    const { Text, Url } = content.attributes;

    return (
        <IonGrid className="padding-materials">
            <IonRow className="ion-padding">
                <IonCol>
                    <IonText className="text-materials">
                        {Text || 'Ups! Ocorreu um erro e não foi possível carregar o vídeo.'}
                    </IonText>
                </IonCol>
            </IonRow>

            <IonRow className="ion-padding video-row">
                <IonCol>
                    <KindmapVideoPlayer url={Url} />
                </IonCol>
            </IonRow>

            <div className="spacer-div-materials-bottom-exercise"></div>
        </IonGrid>
    );
};

export default React.memo(KindmapContentVideo);
