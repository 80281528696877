import React, { useEffect } from "react";
import { useIonRouter, IonButton, IonContent, isPlatform } from "@ionic/react";
import "./Home.css";

const Home: React.FC = () => {
  const router = useIonRouter();

  //verificar se faz sentido esta funcionalidade
  useEffect(() => {
    if (isPlatform("mobile") && router.routeInfo.pathname !== "/login") {
      router.push("/login", "forward", "replace");
    }
  }, [router]);

  const handleLoginRedirect = () => {
    router.push("/login", "forward");
  };

  return (
    <IonContent className="home__container">
      <div className="home__content">
        <h1 className="home__title">Bem vindo ao <br /><span className="home__title--strong">Projeto KindMap</span>!</h1>
        <p className="home__text">Bla, bla, bla...</p>
        <IonButton className="home__button" onClick={handleLoginRedirect}>Login</IonButton>
      </div>
    </IonContent>
  );
};

export default Home;