import React, { ReactNode, useContext } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
  IonRouterContext,
} from "@ionic/react";
import DOMPurify from "dompurify";
import "./KindmapCard.css";

interface KindmapCardProps {
  children?: ReactNode;
  cardId: string;
  title: string;
  description: string;
  icon: string;
  duration: string;
  url: string;
  lock: boolean;
}

const KindmapCard: React.FC<KindmapCardProps> = ({
  cardId,
  title,
  description,
  icon,
  duration,
  url,
  lock,
}) => {
  const ionRouterContext = useContext(IonRouterContext);

  // Sanitize the HTML content
  const rawText =
    description ||
    "Ups! Ocorreu um erro e não foi possível carregar o conteúdo.";
  const sanitizedContent = DOMPurify.sanitize(rawText);

  const openUrl = () => {
    if (!lock) {
      ionRouterContext.push(url);
    }
  };

  // Function to limit description text to 150 characters
  const getShortDescription = (text: string) => {
    return text.length > 100 ? `${text.slice(0, 100)}...` : text;
  };

  return (
    <IonGrid className="no-padding-grid">
      <IonCard
        className={`ion-card ${lock ? "overlay-card" : ""}`}
        onClick={openUrl}
      >
        <IonCardContent className="ion-card-content">
          <IonRow>
            <IonCol>
              {icon && (
                <IonCol size="1">
                  <img
                    className={`ion__icon--kindmap ${lock ? "overlay-color" : ""}`}
                    src={icon}
                    alt="Logo do conteúdo"
                  />
                </IonCol>
              )}

              <IonCol size="11">
                <IonCardTitle className={`ion-card-title ${lock ? "overlay-color" : ""}`}>{title}</IonCardTitle>
              </IonCol>
            </IonCol>
          </IonRow>

          {description && sanitizedContent && (
            <IonRow className="ion-description-materials">
              <div
                className={`ion-card-sub-title ${lock ? "overlay-color" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: getShortDescription(sanitizedContent),
                }}
              ></div>
            </IonRow>
          )}
        </IonCardContent>
      </IonCard>
    </IonGrid>
  );
};

export default KindmapCard;
