import "./Session.css";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import * as Parse from "parse";
import {
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  useIonViewWillEnter,
  useIonLoading,
  IonText,
  IonLabel,
  IonRow,
  IonRouterContext
} from "@ionic/react";

/* Componentes */
import "../../components/KindmapDrawer";
import KindmapDrawer from "../../components/KindmapDrawer";
import UIContext from "../private/hide-context";
import KindmapDrawerContainer from "../../components/KindmapDrawerContainer";
import KindmapMeditation from "../../components/KindmapMeditation";
import KindmapMaterials from "../../components/KindmapMaterials";

/* Icons */
import MeditationSvg from '../../components/Icons/Meditation.svg';
import MaterialsSvg from '../../components/Icons/materiais.svg';
import SessionsSvg from '../../components/Icons/sessions.svg';

interface ParametersURL {
  id: string
}

const Session: React.FC = (props) => {
  const { setShowTabs } = React.useContext(UIContext);
  const [session, setSession] = useState<Parse.Object>({} as Parse.Object);
  const [showLoading, dismissLoading] = useIonLoading();
  const ionRouterContext = useContext(IonRouterContext);

  let urlParams = useParams<ParametersURL>();
  //console.log("[Session.tsx] ", "URL: ", window.location.pathname, " -> id: ", urlParams.id);
  //console.log("[Session.tsx] ", "Initial Session state: ", session);

  useIonViewWillEnter(() => {
    showLoading();

    let currentSession: Parse.Object;
    let currentUserData: Parse.Object;

    const currentUser = Parse.User.current();
    if (currentUser === undefined) {
      ionRouterContext.push("/login");
      dismissLoading();
      return;
    }

    var query = new Parse.Query("Classes");
    query.equalTo("objectId", urlParams.id);

    const requestSession = query.find();
    requestSession.then((results: Parse.Object[]) => {
      //console.log("[Session.tsx] ", "Query Results: ", results);
      currentSession = results[0];
      //console.log("[Session.tsx] ", "Current Session : ", currentSession);

      const requestUserData = new Promise<Parse.Object>((resolve, reject) => {
        const queryUserData = new Parse.Query("UserData");
        queryUserData.equalTo("userId", currentUser.id);
        queryUserData.equalTo("dataId", currentSession.id);
        queryUserData.equalTo("dataType", "CLASS");
        queryUserData.find().then((userData: Parse.Object[]) => {
          if (userData && userData.length > 0) {
            currentUserData = userData[0];
            currentSession.set("userData", currentUserData);
            resolve(currentSession);
            return;
          } /*else console.error("[ERROR(!)]: Não existem dados sobre a sessão do utilizador.", userData);*/

          const UserDataClass = Parse.Object.extend("UserData");
          let newUserData = new UserDataClass();
          newUserData.set("userId", currentUser.id);
          newUserData.set("dataId", currentSession.id);
          newUserData.set("dataType", "CLASS");
          newUserData.save().then((currentUserData: Parse.Object) => {
            currentSession.set("userData", currentUserData);
            resolve(currentSession);
          });
        });
      });
      return requestUserData;
    }).then((currentSession: Parse.Object) => {
      const requestMaterials = currentSession.get("Materials").query().find();
      return requestMaterials;
    }).then((materials: Parse.Object[]) => {
      //console.log("[Session.tsx] ", "Material: ", materials[0]);
      const currentMaterial = materials[0];
      currentSession.set("Materials", currentMaterial);
      const requestContents = currentMaterial.get("Contents").query().find();
      return requestContents;
    }).then((contents: Parse.Object[]) => {
      //console.log("[Session.tsx] ", "Contents: ", contents);
      currentSession.get("Materials").set("Contents", contents);
      return Promise.resolve(currentSession);
    }).then((currentSession) => {
      return new Promise<Parse.Object>((resolve, reject) => {
        currentSession.get("Child").query().find().then((children: Parse.Object[]) => {
          currentSession.set("Child", children[0]);
          resolve(currentSession);
        })
      })
    }).then(async (currentSession) => {
      const currentMeditation = await currentSession.get("Meditation").query().find();
      //console.log("[Session.tsx] ", "Meditation: ", currentMeditation);
      currentSession.set("Meditation", currentMeditation[0]);
      setSession(currentSession);
      //console.log("[Session.tsx] ", "Final Current Session : ", currentSession);
    })
      .catch((error) => {
        console.error(error);
      }).finally(() => {
        dismissLoading();
      });
    dismissLoading();
  });

  useEffect(() => {
    setShowTabs(false);

    return () => {
      setShowTabs(true);
    };
  });

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons className="space-button" slot="start">
            <IonBackButton className="color-back-icon" text="" defaultHref="/app/sessions/" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent scrollY={false}>
        <IonContent scrollY={true}>
          <IonRow className="ion-padding-row-title">
            <IonLabel className="ion-title-session__strong">{session?.attributes?.Title}</IonLabel>
          </IonRow>
          <IonRow className="ion-padding-row">
            <IonText className="ion-text-session">{session?.attributes?.Text}</IonText>
          </IonRow>
          <div className="spacer"></div>
        </IonContent>
        <KindmapDrawerContainer>

          {/* Materiais de apoio (conteúdo) */}
          <KindmapDrawer
            title="Conteúdo"
            height={600}
            offset={120}
            depth={1}
            header={60}
            icon={MaterialsSvg}
            color={'#A1D6B3'}
            url={''}
            enable={true}
            content={<KindmapMaterials sessionId={session?.id} material={session?.attributes?.Materials} contents={session?.attributes?.Materials?.attributes?.Contents} />}
          />

          {/* Meditação */}
          <KindmapDrawer
            title="Meditação"
            height={600}
            offset={80}
            depth={2}
            header={40}
            icon={MeditationSvg}
            color={'#FFFFFF'}
            url={''}
            enable={true}
            content={<KindmapMeditation sessionId={session?.id} meditation={session?.attributes?.Meditation} userData={session?.attributes?.userData} />}
          />

          {/* Próxima sessão */}
          <KindmapDrawer
            title="Próxima Sessão"
            height={100}
            offset={0}
            depth={3}
            header={60}
            icon={SessionsSvg}
            color={'#D1D4E0'}
            url={`/app/session/${session?.attributes?.Child?.id}`}
            enable={session?.attributes?.userData?.attributes?.completed}
            content={''}
          />

        </KindmapDrawerContainer>
      </IonContent>
    </IonPage>
  );
};

export default Session;
