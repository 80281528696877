import { IonRow, IonCol, IonButtons, IonButton, IonIcon, useIonLoading, IonGrid } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import './KindmapAudioPlayer.css';

/* ICONS */
import ButtonPlay from './Icons/botao-play.svg';
import ButtonPause from './Icons/botao-pause.svg';

interface KindmapAudioPlayerProps {
    url: string;
    title: string;
}

const KindmapAudioPlayer: React.FC<KindmapAudioPlayerProps> = ({ url, title }) => {
    const [playing, setPlaying] = useState(false);
    const [present, dismiss] = useIonLoading();
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);

    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);

    useEffect(() => {
        if (url && waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#A7A6A6',
                progressColor: '#F4CE79',
                height: 75,
                barWidth: 3,
                pixelRatio: 1,
                barMinHeight: 1,
                normalize: true,
                responsive: true,
                cursorColor: 'transparent',
            });

            wavesurfer.current.load(url);

            wavesurfer.current.on('ready', () => {
                setAudioDuration(wavesurfer.current!.getDuration());
                dismiss();
            });

            wavesurfer.current.on('audioprocess', () => {
                setCurrentTime(wavesurfer.current!.getCurrentTime());
            });

            wavesurfer.current.on('finish', () => setPlaying(false));

            return () => {
                wavesurfer.current?.destroy();
            };
        } else {
            dismiss();
            console.error('Audio URL is not available or waveformRef is not ready.');
        }
    }, [present, dismiss, url]);

    const formatTime = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60).toString();
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const handlePlayPause = () => {
        if (wavesurfer.current) {
            setPlaying(!playing);
            wavesurfer.current.playPause();
        }
    };

    return (
        <IonGrid>
            <IonRow className="ion-padding ion-text-center">
                <IonCol className='meditate-p'>
                    Em reprodução:
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol className="meditate-title">{title}</IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
                <IonCol>
                    <span className="timer">{formatTime(currentTime)}</span>
                    &nbsp;<span className="timer">/</span>&nbsp;
                    <span className="timer">{formatTime(audioDuration)}</span>
                </IonCol>
            </IonRow>

            <div className="waveform-container">
                <div className="waveform-container-wave" ref={waveformRef}></div>
                <div className="waveform-container-controls">
                    <IonButtons>
                        <IonButton className="button-meditation" onClick={handlePlayPause}>
                            <IonIcon
                                className="ion-icon-control ion-icon-control-play"
                                src={playing ? ButtonPause : ButtonPlay}
                            ></IonIcon>
                        </IonButton>
                    </IonButtons>
                </div>
            </div>
        </IonGrid>
    );
};

export default KindmapAudioPlayer;