import React from "react";
import { Redirect, Route } from "react-router";
import { IonButton, IonButtons, IonContent, IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTitle, IonToolbar } from "@ionic/react";
import './Private.css';

import Logo from '../../components/Icons/logo_horizontal.svg';

/* Icones personalizados Menu Mobile */
import HomeSvg from '../../components/Icons/home.svg';
import SessoesSvg from '../../components/Icons/sessions.svg';
import ProfileSvg from '../../components/Icons/profile.svg';

/* Contexto */
import UIContext from "../private/hide-context";

/* Tabs */
import Material from "../private/Material";
import Sessions from "../private/Sessions";
import Session from "../private/Session";
import Profile from "../private/Profile";
import Home from "../private/Home";
import Meditation from '../private/Meditation';

const PrivateContainer: React.FC = () => {
  const { showTabs } = React.useContext(UIContext);

  let tabBarStyle = showTabs ? undefined : { display: "none" };

  return (
    <IonPage id="private-container">
      {/* Menu L-XL */}
      <IonToolbar slot="top" className="ion-hide-sm-down">
        <IonIcon className="logo" icon={Logo} />
        <IonButtons slot="end">
          <IonButton className="no-ripple active" routerLink={"/app/home"}>
            <IonTitle className="green-hover">Home</IonTitle>
          </IonButton>
          <IonButton className="no-ripple" routerLink={"/app/sessions"}>
            <IonTitle className="green-hover">Módulos</IonTitle>
          </IonButton>
          <IonButton className="no-ripple" routerLink={"/app/meditations"}>
            <IonTitle className="green-hover">Meditações</IonTitle>
          </IonButton>
          <IonButton className="no-ripple" routerLink={"/app/profile"}>
            <IonTitle className="green-hover">Perfil</IonTitle>
          </IonButton>
        </IonButtons>
      </IonToolbar>

      <IonContent>
        <IonRouterOutlet>
          <Route path="/app/home" component={Home} exact />

          <Route path="/app/meditations">
            {/* meditações > falta fazer a componente */}
          </Route>

          <Route path="/app/sessions" component={Sessions} />

          <Route path="/app/session/:id" component={Session} />

          <Route path="/app/session/:idsession/meditations/:idmeditation" component={Meditation} />

          <Route path="/app/session/:idsession/materials/:idcontent" component={Material} />

          <Route path="/app/profile" component={Profile} />

          <Redirect exact from="/app" to="/app/home" />
        </IonRouterOutlet>
      </IonContent>

      {/* Menu S-M*/}
      <IonTabBar slot="bottom" className="tabar-mobile ion-hide-sm-up" style={tabBarStyle}>
        <IonTabButton className="tabutton-mobile no-ripple" tab="home" href="/app/home">
          <IonIcon icon={HomeSvg} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>

        <IonTabButton className="tabutton-mobile no-ripple" tab="sessoes" href="/app/sessions">
          <IonIcon icon={SessoesSvg} />
          <IonLabel>Módulos</IonLabel>
        </IonTabButton>

        <IonTabButton className="tabutton-mobile no-ripple" tab="sessoes" href="/app/meditations">
          <IonIcon icon={SessoesSvg} />
          <IonLabel>Meditações</IonLabel>
        </IonTabButton>

        <IonTabButton className="tabutton-mobile no-ripple" tab="perfil" href="/app/profile">
          <IonIcon icon={ProfileSvg} />
          <IonLabel>Perfil</IonLabel>
        </IonTabButton>
      </IonTabBar>

    </IonPage>
  );
};

export default PrivateContainer;