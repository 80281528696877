import React from 'react';
import './KindmapDrawerContainer.css';

interface Props {
  children: React.ReactNode;
}

const KindmapDrawerContainer: React.FC<Props> = (props) => {
  return (
    <div className="kindmap-drawer-container">
      {props.children}
    </div>

  );
}

export default KindmapDrawerContainer;