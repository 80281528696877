import React, { ReactNode } from 'react';

interface MyComponentProps {
  children: ReactNode;
}

export const Context = React.createContext<any>(undefined);
export const UIProvider: React.FC<MyComponentProps> = ({ children }) => {
  const [showTabs, setShowTabs] = React.useState(true);
  let state = {
    showTabs,
    setShowTabs,
  };

  return <Context.Provider value={state}>{children}</Context.Provider>;
}

export default Context;
