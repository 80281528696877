import React, { useState } from "react";
import { Redirect, Route } from "react-router";
import { IonContent, IonPage, IonRouterOutlet, IonToolbar } from "@ionic/react";
import "./Public.css";

/*PAGES*/
import Home from "../public/Home";
import About from "../public/About";
import Investigacao from "../public/Investigacao";
import Servicos from "../public/Servicos";

interface MenuItem {
  title: string;
  path: string;
  component: React.FC;
}

const PublicContainer: React.FC = () => {
  const menuItems: MenuItem[] = [
    { title: "Kindmap", path: "/public/home", component: Home },
    { title: "Sobre o Projeto", path: "/public/about", component: About },
    { title: "Investigação", path: "/public/investigacao", component: Investigacao },
    { title: "Serviços de Apoio de Ajuda", path: "/public/servicos", component: Servicos },
  ];

  const [expandedColumn, setExpandedColumn] = useState<number>(0);

  const handleColumnClick = (index: number) => {
    setExpandedColumn((prevIndex) => (prevIndex === index ? -1 : index));
  };

  const Column: React.FC<{ index: number; menuItem: MenuItem }> = ({ index, menuItem }) => (
    <div
      className={`column ${expandedColumn === index ? "expanded" : ""}`}
      key={index}
      onClick={() => handleColumnClick(index)}
    >
      <div className="column-p">
        <p>+</p>
        <p className="number">{menuItem.title}</p>
      </div>
      {expandedColumn === index && (
        <div className="expanded">
          {React.createElement(menuItem.component)}
        </div>
      )}
    </div>
  );

  return (
    <IonPage>
      <IonToolbar>
        <div className="container">
          {menuItems.map((menuItem, index) => (
            <Column key={index} index={index} menuItem={menuItem} />
          ))}
        </div>
      </IonToolbar>

      <IonContent>
        <IonRouterOutlet>
          {menuItems.map((item) => (
            <Route key={item.path} path={item.path} component={item.component} />
          ))}
          <Route exact path="/public">
            <Redirect to="/public/home" />
          </Route>
        </IonRouterOutlet>
      </IonContent>
    </IonPage>
  );
};

export default PublicContainer;