import { IonBackButton, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonPage, IonRouterContext, IonRow, IonToolbar, useIonLoading, useIonViewWillEnter } from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import WaveSurfer from "wavesurfer.js";
import './Meditation.css';

/* ICONS */
import ButtonPlay from '../../components/Icons/botao-play.svg';
import ButtonPause from '../../components/Icons/botao-pause.svg';

interface ParametersURL {
    idsession: string,
    idmeditation: string
}

const Meditation: React.FC = () => {
    let urlParams = useParams<ParametersURL>();

    const [userData, setUserData] = useState<Parse.Object[] | null>(null);
    const [meditation, setMeditation] = useState<Parse.Object>();
    const [playing, setPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [showLoading, dismissLoading] = useIonLoading();
    const ionRouterContext = useContext(IonRouterContext);

    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);

    useEffect(() => {
        const audioUrl = meditation?.attributes?.Audio?.url();

        if (audioUrl && waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#F4CE79',
                progressColor: '#A7A6A6',
                height: 75,
                barWidth: 3,
                pixelRatio: 1,
                barMinHeight: 1,
                normalize: true,
                responsive: true,
                cursorColor: 'transparent',
            });

            wavesurfer.current.load(audioUrl);

            wavesurfer.current.on('ready', () => {
                setAudioDuration(wavesurfer.current?.getDuration() || 0);
                dismissLoading();
            });

            wavesurfer.current.on('audioprocess', () => {
                setCurrentTime(wavesurfer.current?.getCurrentTime() || 0);
            });

            wavesurfer.current.on('finish', () => {
                setPlaying(false);
                if (userData && userData.length > 0) {
                    const userDataObj = userData[0];
                    userDataObj.set('completed', true);
                    userDataObj.save().catch((error) => {
                        console.error("Error saving user data: ", error);
                    });
                } else {
                    console.error("User data is not available.");
                }
            });

            return () => {
                wavesurfer.current?.destroy();
            };
        } else {
            dismissLoading();
            console.error('Audio URL is not available or waveformRef is not ready.');
        }
    }, [meditation, showLoading, dismissLoading, userData]);

    const formatTime = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60).toString();
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
        wavesurfer.current?.playPause();
    };

    useIonViewWillEnter(() => {
        showLoading();

        const currentUser = Parse.User.current();
        //console.log("[CURRENT LOGGED USER]: ", currentUser);
        if (!currentUser) {
            ionRouterContext.push("/login");
            dismissLoading();
            return;
        }

        const queryUserData = new Parse.Query("UserData");
        queryUserData.equalTo("userId", currentUser.id);
        queryUserData.equalTo("dataType", "CLASS");
        //queryUserData.equalTo("completed", false);
        queryUserData.find().then((userData: Parse.Object[]) => {
            if (userData) {
                setUserData(userData);
            } else console.error("[ERROR(!)]: Não existem dados sobre as sessões do utilizador.", userData);
        });

        var query = new Parse.Query("Meditation");
        query.equalTo("objectId", urlParams.idmeditation);

        const data = query.find();
        data.then((meditation: Parse.Object[]) => {
            setMeditation(meditation[0]);
        });

        dismissLoading();
    });

    return (
        <IonPage>
            <IonHeader className="ion-no-border">
                <IonToolbar>
                    <IonButtons className="space-button" slot="start">
                        <IonBackButton className="color-back-icon" text="" defaultHref={"/app/home"} />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="kindmap-meditation">
                <IonRow className="ion-padding ion-text-center">
                    <IonCol>
                        <p className="meditate-p">Em reprodução:</p>
                        <h1 className="meditate-title">{meditation?.attributes?.Title}</h1>
                    </IonCol>
                </IonRow>

                <IonRow className="ion-text-center">
                    <IonCol>
                        <span className="timer">{formatTime(currentTime)}</span>&nbsp;
                        <span className="timer">/</span>&nbsp;
                        <span className="timer">{formatTime(audioDuration)}</span>
                    </IonCol>
                </IonRow>

                <div className="waveform-container">
                    <div className="waveform-container-wave" ref={waveformRef}></div>
                    <hr className="borderline" />
                    <div className="waveform-container-controls">
                        <IonButtons>
                            <IonButton className="button-meditation" onClick={handlePlayPause}>
                                <IonIcon
                                    className="ion-icon-control ion-icon-control-play"
                                    src={playing ? ButtonPause : ButtonPlay}
                                />
                            </IonButton>
                        </IonButtons>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );

}

export default Meditation;