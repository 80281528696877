import React, { useState, useEffect, useContext } from "react";
import { useParams } from 'react-router';
import { IonBackButton, IonHeader, IonToolbar, IonButtons, IonContent, IonPage, IonRow, IonLabel, IonCard, useIonViewWillEnter, useIonLoading, IonRouterContext } from '@ionic/react';
import UIContext from "../private/hide-context";
import "./Material.css";


import KindmapContentVideo from "../../components/KindmapContentVideo";
import KindmapContentText from "../../components/KindmapContentText";
import KindmapContentAudio from "../../components/KindmapContentAudio";
import KindmapContentExercise from "../../components/KindmapContentExercise";

interface ParametersURL {
  idsession: string,
  idcontent: string
}

const Material: React.FC = () => {
  const { setShowTabs } = React.useContext(UIContext);
  let urlParams = useParams<ParametersURL>();
  //console.log( "[Session.tsx] ", "URL: ", window.location.pathname," -> idsession: ", urlParams.idsession, "; idcontent:", urlParams.idcontent);

  const [content, setContent] = useState<Parse.Object>();
  const [showLoading, dismissLoading] = useIonLoading();
  const ionRouterContext = useContext(IonRouterContext);

  useIonViewWillEnter(() => {
    showLoading();

    const currentUser = Parse.User.current();
    //console.log("[CURRENT LOGGED USER]: ", currentUser);
    if (!currentUser) {
      ionRouterContext.push("/login");
      dismissLoading();
      return;
    }

    var query = new Parse.Query("Content");
    query.equalTo("objectId", urlParams.idcontent);
    const requestContent = query.find();
    requestContent.then((contents: Parse.Object[]) => {
      setContent(contents[0]);
      dismissLoading();
    });
    dismissLoading();
  });

  useEffect(() => {
    setShowTabs(false);

    return () => {
      setShowTabs(true);
    };
  });

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons className="space-button" slot="start">
            <IonBackButton className="color-back-icon" text="" defaultHref={"/app/session/" + urlParams?.idsession} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonRow className="first-title-materials">
        <IonLabel>{content?.attributes?.Description}</IonLabel>
      </IonRow>


      <IonContent scrollY={false}>

        <IonCard className="background-material">
          <IonContent className="background-color-content" scrollY={true}>
            {content?.attributes?.Category === "video" && <KindmapContentVideo content={content as any} />}
            {content?.attributes?.Category === "text" && <KindmapContentText content={content as any} />}
            {content?.attributes?.Category === "audio" && <KindmapContentAudio content={content as any} />}
            {content?.attributes?.Category === "exercise" && <KindmapContentExercise content={content as any} />}
          </IonContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Material;
