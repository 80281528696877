import React, { useEffect, useRef } from 'react';
import { createGesture, IonCard, IonLabel, IonCardContent, IonCardHeader, IonContent, IonIcon, IonGrid } from '@ionic/react';
import './KindmapDrawer.css';

interface KindmapDrawerProps {
  title: string,
  height: number, //fixo
  offset: number,
  depth: number,
  header: number,
  icon: string,
  color: string,
  url: string,
  enable: boolean,
  content: React.ReactNode
}

const KindmapDrawer: React.FC<KindmapDrawerProps> = (props: KindmapDrawerProps) => {

  const drawerRef: React.MutableRefObject<any> = useRef();

  useEffect(() => {
    if (props.url) {
      return;
    }

    let c = drawerRef.current;
    const gesture = createGesture({
      el: c,
      gestureName: "my-swipe",
      direction: "y",
      /**
       * when moving, we start to show more of the drawer
       */
      onMove: event => {
        if (event.deltaY < -(props.height * 0.75)) return;

        // closing with a downward swipe
        if (event.deltaY > (props.height * 0.05)) {
          c.style.transform = "";
          c.dataset.open = "false";
          c.style.zIndex = props.depth;
          return;
        }

        c.style.transform = `translateY(${event.deltaY}px)`;
      },
      /**
       * when the moving is done, based on a specific delta in the movement; in this
       * case that value is -150, we determing the user wants to open the drawer.
       *
       * if not we just reset the drawer state to closed
       */
      onEnd: event => {
        c.style.transition = ".5s ease-out";

        if (event.deltaY < -(props.height * 0.1) && c.dataset.open !== "true") {
          const transY = (props.height * 0.9 - props.header - props.offset) > (window.screen.height * 0.9 - 88 - props.header - props.offset) ? (window.screen.height * 0.9 - 88 - props.header - props.offset) : (props.height * 0.9 - props.header - props.offset);
          c.style.transform = `translateY(${-(transY)}px`;
          c.dataset.open = "true";
          c.style.zIndex = (props.depth + 100);
        }
      }
    });
    gesture.enable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTo = () => {
    if (props.url && props.enable) {
      window.location.href = props.url;
      return;
    }
  }

  const toggleDrawer = () => {
    if (props.url && props.enable) {
      window.location.href = props.url;
      return;
    }

    let current = drawerRef.current;
    if (!current) {
      return;
    }

    if (current.dataset.open === "true") {
      current.style.transition = ".5s ease-out";
      current.style.transform = "translateY(0px)";
      current.dataset.open = "false";
      current.style.zIndex = props.depth;
    } else {
      current.style.transition = ".5s ease-in";
      const transY = (props.height * 0.9 - props.header - props.offset) > (window.screen.height * 0.9 - 88 - props.header - props.offset) ? (window.screen.height * 0.9 - 88 - props.header - props.offset) : (props.height * 0.9 - props.header - props.offset);
      current.style.transform = `translateY(${-(transY)}px) `; //
      current.dataset.open = "true";
      current.style.zIndex = (props.depth + 100);
    }
  };

  const bottomDrawerStyle = {
    height: `${props.height}px`,
    bottom: `${-(props.height - props.header - props.offset)}px`,
    zIndex: `${props.depth}`,
    background: `${props.color}`
  }

  const cardContentStyle = {
    "--background": `${props.color}`
  }

  return (
    <IonCard className="bottom-drawer" ref={drawerRef as any} style={bottomDrawerStyle} onClick={goTo}>
      <div className="button-drawer-header-container">
        <div className="button-drawer-header" onClick={toggleDrawer}>&nbsp;</div>
      </div>
      <IonCardHeader className="ion-no-padding ion-padding-left icon-title-container">
        <IonIcon src={props.icon}></IonIcon>
        <IonLabel className={props?.enable ? "ion-label-session-enable ion-label-session" : "ion-label-session-disable ion-label-session"}>{props?.title}</IonLabel>
      </IonCardHeader>
      <IonCardContent className="ion-card-content-container">
        <IonGrid className="ion-card-content-container-grid">
          <IonContent className="ion-card-content-container-grid-content" style={cardContentStyle} forceOverscroll={false}>
            {props.content}
          </IonContent>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default KindmapDrawer;