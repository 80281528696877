import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonRow, IonCol, IonLabel, IonItem, IonInput, IonButton, IonToast } from "@ionic/react";
import { alertCircleOutline } from 'ionicons/icons';
import '../../style/Public.css';

const Login: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showToast, setShowToast] = useState(false);

    /*useEffect(() => {
        //console.log("[CURRENT LOGGED USER]: ", currentUser);
        const currentUser = Parse.User.current();
        if (currentUser !== null) {
            history.push("/app/home");
            setLoading(false);
            return;
        }
    }, [history]);*/

    const doLogin = async (email: string, password: string): Promise<Parse.User | null> => {
        try {
            const user = await Parse.User.logIn(email, password);
            return user.isValid() ? user : null;
        } catch (error) {
            throw new Error("Não foi possível iniciar sessão. Por favor, verifique as suas credenciais.");
        }
    };

    const doSignIn = async (): Promise<void> => {
        if (!email || !password) {
            setErrorMessage("Por favor, preencha todos os campos.");
            setShowToast(true);
            return;
        }
        setLoading(true);
        try {
            const user = await doLogin(email, password);
            if (user) {
                history.push("/app/home");
            } else {
                setErrorMessage("O início de sessão não foi bem sucedido. Verifique as suas credenciais.");
                setShowToast(true);
            }
        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("Ocorreu um erro desconhecido.");
            }
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <IonContent>
            <IonRow className="ion-padding">
                <IonCol className="ion-hide-md-down"></IonCol>
                <IonCol>
                    <IonLabel className="specialDiv">Bem-vind@ à <br />KindMap</IonLabel>
                    <p className="main__content">Insira as credenciais que lhe foram disponibilizadas pelos responsáveis do projeto para realizar a autenticação na aplicação.</p>
                    <IonItem lines="none" className="login-style padding-form">
                        <IonLabel position="stacked" className="main__label">E-mail:</IonLabel>
                        <IonInput
                            className="main__input"
                            type="email"
                            value={email}
                            onIonChange={(e) => setEmail(e.detail.value!)}
                        />
                    </IonItem>
                    <IonItem lines="none" className="padding-item">
                        <IonLabel position="stacked" className="main__label">Password:</IonLabel>
                        <IonInput
                            className="main__input"
                            type="password"
                            value={password}
                            onIonChange={(e) => setPassword(e.detail.value!)}
                        />
                    </IonItem>
                    <div>
                        <IonButton
                            className="main__button--color"
                            expand="block"
                            onClick={doSignIn}
                            disabled={loading}
                        >
                            {loading ? "Aguarde..." : "Login"}
                        </IonButton>
                    </div>
                </IonCol>
                <IonCol className="ion-hide-md-down"></IonCol>
            </IonRow>

            <IonToast
                position="top"
                isOpen={showToast}
                icon={alertCircleOutline}
                message={errorMessage || undefined}
                duration={2000}
                color={"primary"}
                onDidDismiss={() => setShowToast(false)}
            />
        </IonContent>
    );
};

export default Login;