import React, { useRef, useEffect, useState } from 'react';
import { IonRow, IonCol, IonIcon, IonButton, IonButtons, useIonLoading } from '@ionic/react';
import WaveSurfer from 'wavesurfer.js';
import './KindmapMeditation.css';

/* ICONS */
import ButtonPlay from './Icons/botao-play.svg';
import ButtonPause from './Icons/botao-pause.svg';

interface KindmapMeditationProps {
    sessionId: string;
    meditation: Parse.Object;
    userData: Parse.Object;
}

const KindmapMeditation: React.FC<KindmapMeditationProps> = ({ meditation, userData }) => {
    const [playing, setPlaying] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [present, dismiss] = useIonLoading();

    const waveformRef = useRef<HTMLDivElement | null>(null);
    const wavesurfer = useRef<WaveSurfer | null>(null);

    useEffect(() => {
        const audioUrl = meditation?.attributes?.Audio?.url();

        if (audioUrl && waveformRef.current) {
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#F4CE79',
                progressColor: '#A7A6A6',
                height: 75,
                barWidth: 3,
                pixelRatio: 1,
                barMinHeight: 1,
                normalize: true,
                responsive: true,
                cursorColor: 'transparent',
            });

            wavesurfer.current.load(audioUrl);

            wavesurfer.current.on('ready', () => {
                setAudioDuration(wavesurfer.current?.getDuration() || 0);
                dismiss();
            });

            wavesurfer.current.on('audioprocess', () => {
                setCurrentTime(wavesurfer.current?.getCurrentTime() || 0);
            });

            wavesurfer.current.on('finish', () => {
                setPlaying(false);
                userData.set('completed', true);
                userData.save();
            });

            return () => {
                wavesurfer.current?.destroy();
            };
        } else {
            dismiss();
            /*console.error('Audio URL is not available or waveformRef is not ready.');*/
        }
    }, [meditation, userData, present, dismiss]);

    const formatTime = (time: number) => {
        const minutes = Math.floor((time % 3600) / 60).toString();
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    const handlePlayPause = () => {
        setPlaying(!playing);
        wavesurfer.current?.playPause();
    };

    return (
        <div className="kindmap-meditation">
            <IonRow className="ion-padding ion-text-center">
                <IonCol>
                    <p className="meditate-p">Em reprodução:</p>
                    <h1 className="meditate-title">{meditation?.attributes?.Title}</h1>
                </IonCol>
            </IonRow>

            <IonRow className="ion-text-center">
                <IonCol>
                    <span className="timer">{formatTime(currentTime)}</span>&nbsp;
                    <span className="timer">/</span>&nbsp;
                    <span className="timer">{formatTime(audioDuration)}</span>
                </IonCol>
            </IonRow>

            <div className="waveform-container">
                <div className="waveform-container-wave" ref={waveformRef}></div>
                <hr className="borderline" />
                <div className="waveform-container-controls">
                    <IonButtons>
                        <IonButton className="button-meditation" onClick={handlePlayPause}>
                            <IonIcon
                                className="ion-icon-control ion-icon-control-play"
                                src={playing ? ButtonPause : ButtonPlay}
                            />
                        </IonButton>
                    </IonButtons>
                </div>
            </div>
        </div>
    );
};

export default KindmapMeditation;