/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  IonRow,
  IonContent,
  IonLabel,
  IonIcon,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonCol,
  useIonViewWillEnter,
  useIonLoading,
  IonRouterContext,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import KindmapCard from "../../components/KindmapCard";

// CSS
import "./Home.css";
/*import KindmapContentFilter from "../../components/KindmapContentFilter";*/

//Icons
import CheckSvg from "../../components/Icons/check-session.svg";
import UnCheckSvg from "../../components/Icons/un-check.svg";
import MeditationSvg from "../../components/Icons/Meditation.svg";
import ExerciceSvg from "../../components/Icons/Exercice.svg";
import TextSvg from "../../components/Icons/Text.svg";
import VideoSvg from "../../components/Icons/Video.svg";
import AudioSvg from "../../components/Icons/Audio.svg";

const Home: React.FC = () => {
  const [userData, setUserData] = useState<Parse.Object[] | null>(null);
  const [session, setSession] = useState<Parse.Object | null>(null);
  const [meditation, setMeditation] = useState<Parse.Object | null>(null);
  const [material, setMaterial] = useState<Parse.Object[] | null>(null); //const [contents, setContent] = useState<Parse.Object[] | null>(null);
  const [contents, setContent] = useState<Parse.Object[]>([]);
  const [filteredContents, setFilteredContent] = useState<Parse.Object[]>([]);
  const [showLoading, dismissLoading] = useIonLoading();
  const ionRouterContext = useContext(IonRouterContext);

  /**
   * The function `getMeditationsFromSession` retrieves meditation data from a session and sets it
   * using React state.
   * @param _meditation - The `_meditation` parameter in the `getMeditationsFromSession` function is a
   * Parse.Object representing a meditation object associated with a session. This function is designed
   * to retrieve data about the meditation from the session and then set that meditation data using the
   * `setMeditation` function. If
   */
  const getMeditationsFromSession = async (
    _meditation: Parse.Object
  ): Promise<void> => {
    try {
      //console.log("Meditation (of session): ", _meditation);
      setMeditation(_meditation);
    } catch (error) {
      console.error(
        "[ERROR (!)]: Erro ao obter os dados sobre a meditação da sessão atual. ",
        _meditation,
        error
      );
    }
  };

  /**
   * The function `getContentsFromSession` asynchronously sets the content based on the input Parse
   * objects.
   * @param {Parse.Object[]} _contents - The `_contents` parameter is an array of Parse.Object
   * instances representing the contents of a session or module.
   */
  const getContentsFromSession = async (
    _contents: Parse.Object[],
    selectedCategory?: string | null
  ): Promise<void> => {
    try {
      //console.log("Contents (of session):", _contents, " <---> ", selectedCategory);

      // If no category is selected, set all contents
      if (!selectedCategory) {
        setContent(_contents);
        setFilteredContent(_contents);
        return;
      }

      // Filter the contents based on the selected category
      const filteredContents = _contents.filter(
        (content) => content.get("Category") === selectedCategory
      );

      setFilteredContent(filteredContents);
    } catch (error) {
      console.error(
        `[ERROR]: Failed to fetch session contents for category '${selectedCategory}'.`,
        error
      );
    }
  };

  /************************************************************************************************************
   * Neste evento carregamos os dados (utilizador, sessão, meditação, etc.) necessários para os               *
   * estados (states) da componente.                                                                          *
   ************************************************************************************************************/
  useIonViewWillEnter(() => {
    showLoading();

    const currentUser = Parse.User.current();
    //console.log("[CURRENT LOGGED USER]: ", currentUser);
    if (!currentUser) {
      ionRouterContext.push("/login");
      dismissLoading();
      return;
    }

    const queryUserData = new Parse.Query("UserData");
    queryUserData.equalTo("userId", currentUser.id);
    queryUserData.equalTo("dataType", "CLASS");
    //queryUserData.equalTo("completed", false);
    queryUserData.find().then((userData: Parse.Object[]) => {
      if (userData) {
        setUserData(userData);
        //console.log("[CURRENT USER DATA]:", userData);
      } else
        console.error(
          "[ERROR(!)]: Não existem dados sobre as sessões do utilizador.",
          userData
        );

      if (userData && userData.length > 0) {
        const queryClasses = new Parse.Query("Classes");
        /*console.log(
          userData,
          " --- ",
          userData.length,
          " --- ",
          userData.length - 1
        );*/
        queryClasses.equalTo(
          "objectId",
          userData[userData.length - 1].get("dataId")
        );
        queryClasses.find().then((sessions) => {
          //console.log(userData[0].get("completed"));
          //console.log("Sessions (of session):", sessions, " <----> ", sessions[0]);
          if (sessions.length > 0) {
            setSession(sessions[0]);

            //console.log("[CURRENT SESSION]:", sessions[0]);
            /************************************************************************************************************
             * Este bloco de código vai buscar a 'meditação' (meditation) relacionada com a sessão atual que o          *
             * utilizador está a realizar de momento. NOTA: É expectável que cada 'sessão' (session) só tenha 1 e       *
             * apenas 1 meditação associada.                                                                            *
             ************************************************************************************************************/
            sessions[0]
              .get("Meditation")
              .query()
              .find()
              .then((meditation: Parse.Object[]) => {
                if (meditation.length === 1)
                  getMeditationsFromSession(meditation[0]);
                else
                  console.error(
                    "[ERROR(!)]: A sessão atual ",
                    sessions[0],
                    " contém mais do que 1 meditação ",
                    meditation.length,
                    meditation
                  );
              });

            /************************************************************************************************************
             * Este bloco de código vai buscar o 'material' (módulo) da sessão atual que o utilizador está a realizar.  *
             * NOTA: Estes materiais (módulos) não são o conteúdo de cada sessão, nesse caso é o 'content'.             *
             ************************************************************************************************************/
            sessions[0]
              .get("Materials")
              .query()
              .find()
              .then((material: Parse.Object[]) => {
                //console.log("[CURRENT MATERIAL (of session)]: ", material);
                setMaterial(material);

                //verificar se esta condição não estoira quando o utilizador não iniciou nenhuma sessão!!!!!
                if (material.length === 1) {
                /************************************************************************************************************
                 * Este bloco de código vai buscar o 'conteúdo' (content) do 'material' (material) atual.                   *
                 ************************************************************************************************************/
                  material[0]
                    .get("Contents")
                    .query()
                    .ascending("order")
                    .find()
                    .then((contents: Parse.Object[]) => {
                      if (contents.length > 0) getContentsFromSession(contents);
                      else
                        console.warn(
                          "[AVISO(!)]: O módulo atual ",
                          material[0],
                          " não contém conteúdo associado.",
                          material,
                          contents.length,
                          contents
                        );
                    });
                } else
                  console.error(
                    "[ERROR(!)]: O utilizador não está a realizar nenhum módulo...",
                    material.length,
                    material
                  );
              });
          } else
            console.error(
              "[ERROR(!)]: Não existe nenhuma sessão atual...",
              sessions.length,
              sessions
            );
        });
      }
      dismissLoading();
    });
  });

  const renderKindmapCard = (content: Parse.Object) => {
    const category = content.get("Category");
    const icon =
      category === "text"
        ? TextSvg
        : category === "audio"
          ? AudioSvg
          : category === "exercise"
            ? ExerciceSvg
            : category === "video"
              ? VideoSvg
              : UnCheckSvg;

    return (
      <KindmapCard
        cardId={content.id}
        title={content.get("Description")}
        description={content.get("Text")}
        icon={icon}
        duration={content.get("Duration")}
        url={"/app/session/" + session?.id + "/materials/" + content?.id}
        lock={false}
      />
    );
  };

  const renderSessionCompletedKindmapCard = async (_userData: Parse.Object) => {
    const sessionCompletedId = _userData.get("dataId");

    try {
      const classesQuery = new Parse.Query("Classes");
      const _classes = await classesQuery.find();

      if (_classes.length === 0) {
        //console.log("No classes found.");
        return null;
      }

      const session = await _classes.find(
        (object) => object.id === sessionCompletedId
      );
      //console.log("########### ", session);

      if (session) {
        return renderKindmapCard(session);
      } else {
        //console.log("Session not found.");
        return null;
      }
    } catch (error) {
      //console.error("Error fetching sessions: ", error);
      return null;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Home</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="top-spacer"></div>

        {/* componente que permite filtrar por conteúdo
        <KindmapContentFilter
          contents={contents}
          onGetContents={getContentsFromSession}
        ></KindmapContentFilter>*/}

        {/* Sessão attual */}
        <IonRow className="ion-padding">
          <IonCol className="content-session-completed-atual">
            <IonLabel className="ion-completed-label ion-padding">
              Meditação do módulo atual
            </IonLabel>
          </IonCol>
        </IonRow>

        <div className="content-container">
          <IonGrid>
            {/* meditações */}
            <IonRow className="content-container-row">
              {meditation ? (
                <ul className="timeline">
                  <li className="padding-card">
                    <div className="timeline-thumb timeline-icon">
                      <IonIcon icon={CheckSvg} />
                    </div>
                    <div className="timeline-item">
                      <IonGrid>
                        <IonCol size="11">
                          <KindmapCard
                            cardId={meditation.get("objectId")}
                            title={meditation.get("Title")}
                            description={meditation.get("Description")}
                            icon={MeditationSvg}
                            duration={meditation.get("Duration")}
                            url={
                              "/app/session/" +
                              session?.id +
                              "/meditations/" +
                              meditation.id
                            }
                            lock={false}
                          ></KindmapCard>
                        </IonCol>
                      </IonGrid>
                    </div>
                  </li>
                </ul>
              ) : (
                <IonGrid>
                    <IonCol size="11">Não existem meditações para ouvir.</IonCol>
                </IonGrid>
              )}
            </IonRow>

            <IonRow className="ion-padding">
              <IonCol className="content-session-completed-atual">
                <IonLabel className="ion-completed-label ion-padding">
                  Conteúdo do módulo atual
                </IonLabel>
              </IonCol>
            </IonRow>

            {/* conteúdos */}
            <IonRow className="content-container-row">
              {filteredContents && filteredContents.length > 0 ? (
                <ul className="timeline">
                  {filteredContents.map((content, index) => (
                    <li key={index} className="padding-card">
                      <div className="timeline-thumb bg-color-blue timeline-icon">
                        <IonIcon icon={UnCheckSvg} />
                      </div>
                      <div className="timeline-item">
                        <IonGrid>
                          <IonCol size="11">
                            {renderKindmapCard(content)}
                          </IonCol>
                        </IonGrid>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <IonGrid>
                    <IonCol size="11">Não existe conteúdo para consultar.</IonCol>
                </IonGrid>
              )}
            </IonRow>

            <div className="spacer-home"></div>
          </IonGrid>
        </div>
      </IonContent>
      <div className="spacer-home">&nbsp;</div>
    </IonPage>
  );
};

export default Home;
