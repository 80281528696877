import React, { useContext } from 'react';
import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRouterContext, IonRow, IonText, IonTitle, IonToolbar, useIonLoading, useIonViewWillEnter } from '@ionic/react';
import * as Parse from 'parse';
import "./Profile.css";
import ProfileSvg from '../../components/Icons/profile.svg';
import SettingsSvg from '../../components/Icons/settings.svg';
import infoSvg from '../../components/Icons/info.svg';

const Profile: React.FC = () => {
  const [showLoading, dismissLoading] = useIonLoading();
  const ionRouterContext = useContext(IonRouterContext);

  useIonViewWillEnter(() => {
    showLoading();

    const currentUser = Parse.User.current();
    //console.log("[CURRENT LOGGED USER]: ", currentUser);
    if (!currentUser) {
      ionRouterContext.push("/login");
      dismissLoading();
      return;
    }
    dismissLoading();
  });

  const logOut = () => {
    Parse.User.logOut().then(() => {
      ionRouterContext.push("/login");
    });
  }
  return (
    <IonPage>
      <IonHeader className="mobile-only">
        <IonToolbar>
          <IonTitle className="title-default">Perfil</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Perfil</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid className="ion-padding">
          <IonCard className="ion-card-profile">
          <IonRow>
              <IonLabel className="profile-label">Lorem ipsum dolor sit amet</IonLabel>
          </IonRow>
          <IonRow>
              <IonText className="profile-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</IonText>
          </IonRow>
          <div className="spacer-div"></div>
            <IonGrid className="activity-row">
              <IonRow className="letter-row">
              <IonCol>Complete</IonCol>
              <IonCol>On Progress</IonCol>
              <IonCol>Incomplete</IonCol>
            </IonRow>
              <IonRow className="number-sessions">
              <IonCol>2</IonCol>
              <IonCol>1</IonCol>
              <IonCol>1</IonCol>
            </IonRow>
          </IonGrid>
        </IonCard>

        {/* USER Settings*/}
          <IonGrid className="ion-padding">
            <IonRow className="padding-row-settings">
              <IonCol size="2" className="ion-text-center ion-icon-settings">
            <IonIcon icon={ProfileSvg}></IonIcon>
          </IonCol>
              <IonCol size="10" className="grid-ioncol">
                <IonLabel className="grid-label">Account</IonLabel>
                <IonText className="grid-text">Lorem ipsum dolor sit amet</IonText>
          </IonCol>
        </IonRow>
        </IonGrid>

          <IonCard className="link-settings-border">
        <IonGrid>
        <IonRow>
            <IonCol>
                  <IonLabel className="settings-label-diary">Diary</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail>
                <IonText>Timeline notes</IonText>
              </IonItem>
            </IonCol> 
        </IonRow>
        <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">Manage account</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail>
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
              </IonCol>    
          </IonRow>
          <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">Reset password</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail lines="none">
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
            </IonCol> 
        </IonRow>
        </IonGrid>
        </IonCard>

        <div className="spacer-settings"></div>

        {/* APP Settings*/}
          <IonGrid className="ion-padding">
            <IonRow className="padding-row-settings">
              <IonCol size="2" className="ion-text-center ion-icon-settings">
            <IonIcon icon={SettingsSvg}></IonIcon>
          </IonCol>
              <IonCol size="10" className="grid-ioncol">
                <IonLabel className="grid-label">Settings</IonLabel>
                <IonText className="grid-text">Lorem ipsum dolor sit amet</IonText>
          </IonCol>
        </IonRow>
        </IonGrid>

          <IonCard className="link-settings-border">
        <IonGrid>
        <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">Acess Data</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail>
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
              </IonCol>    
          </IonRow>
          <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">Notifications</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail lines="none">
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
            </IonCol> 
        </IonRow>
        </IonGrid>
        </IonCard>

        <div className="spacer-settings"></div>
        
        {/* About Settings*/}
          <IonGrid className="ion-padding">
            <IonRow className="padding-row-settings">
              <IonCol size="2" className="ion-text-center ion-icon-settings">
            <IonIcon icon={infoSvg}></IonIcon>
          </IonCol>
              <IonCol size="10" className="grid-ioncol">
                <IonLabel className="grid-label">About</IonLabel>
                <IonText className="grid-text">Lorem ipsum dolor sit amet</IonText>
          </IonCol>
        </IonRow>
        </IonGrid>

          <IonCard className="link-settings-border">
        <IonGrid>
        <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">About Kindmap</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail>
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
              </IonCol>    
          </IonRow>
          <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">Privacy policy</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail>
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
            </IonCol> 
        </IonRow>
        <IonRow>
            <IonCol>
                  <IonLabel className="settings-label">Terms of service</IonLabel>
                  <IonItem className="ion-item" button onClick={() => { }} detail lines="none">
                <IonText>Lorem ipsum dolor sit amet</IonText>
              </IonItem>
            </IonCol> 
        </IonRow>
        </IonGrid>
        </IonCard>

        {/* Logout BUTTON */}

          <IonButton className="ion-padding button-logout" onClick={logOut} fill="outline" color="danger" expand="block">
            <IonLabel className="label-logout">Log Out</IonLabel>
        </IonButton>

          <IonRow className="ion-padding copyright-settings">
        <IonCol size="6">
            <IonLabel>Version 1.0.0</IonLabel>
          </IonCol>

          <IonCol size="6">
              <IonLabel className="copyright">© Kindmap 2021</IonLabel>
          </IonCol>
        </IonRow>

        <div className="spacer-div-final"></div>

        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
