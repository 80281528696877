import React from "react";
import {IonContent } from "@ionic/react";
const Servicos: React.FC = () => {
    return (
        <IonContent>
        <div className="content">
          <h1>Serviços</h1>
        </div>
      </IonContent>
    );
};

export default Servicos;